<template>
  <div class="wrapper-container">
    <div class="describe-container-box">
      <div class="title">运营赋能·方案详情</div>
      <div class="sub-title">运营赋能增长策略</div>
      <div class="large-paragraph-describe">
        全球蛙运营深耕6大渠道，微信、支付宝、云闪付、银行、抖音、快手，全面解析各渠道政策，为零售商获得渠道更多流量、更多支持，充分满足不同类型顾客的消费需求，多场景、多触点、高效快速助力零售商全方位多角度黏住顾客，同时全球蛙为合作伙伴输出商品、活动、会员、导购、订单5大方面的运营策略，通过营销中台云蛙超市及联动组织客户学院，高效链接品牌商与零售商，助力双方完成存量客户激活，增量客户沉淀。
      </div>
    </div>
    <div class="large-image-container">
      <div class="large-image-page-header">
        <div>全域全场景营销助力零售商效率提升收益增长</div>
      </div>
      <div class="large-image-h1225">
        <img src="@/image/retail/img_personal_operate@3x.png" />
      </div>
      <div class="large-image-h1292">
        <img src="@/image/retail/img_public_operate@3x.png" />
      </div>
    </div>
    <div class="func-module-container">
      <div class="func-title">运营赋能·应用案例</div>
      <div class="func-sub-title">赋能零售商打造优质线上直播场景</div>
    </div>
    <div
      class="large-image-h280"
      v-for="src in operatePowerBigImages"
      :key="src"
    >
      <img :src="src" />
    </div>
    <action-sheet module="新零售运营赋能">
      <template #extra-title>
        <div class="extra-title">相关文章</div>
      </template>
    </action-sheet>
  </div>
</template>

<script>
export default {
  data: () => ({
    operatePowerBigImages: [
      require("@/image/retail/img_mth_operate@3x.png"),
      require("@/image/retail/img_csf_operate@3x.png"),
      require("@/image/retail/img_mdj_operate@3x.png"),
    ],
  }),
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/solution.common.scss";
.large-image-container {
  border-radius: 48px 48px 0 0;
  padding-top: 0 !important;
  padding-bottom: 60px !important;
}
.func-module-container {
  background-color: transparent !important;
}
.large-image-h1292 {
  margin-bottom: 0 !important;
}
</style>
